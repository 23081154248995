import styled from "@emotion/styled";

import { color } from "metabase/lib/colors";

export const ChunkyListItem = styled.button<{
  isSelected?: boolean;
  isLast?: boolean;
}>`
  padding: 1.5rem;
  cursor: pointer;
  background-color: ${({ isSelected }) =>
    isSelected ? "var(--mb-color-brand)" : "white"}; /*DX-Comment Changed color("brand") to var(--mb-color-brand)*/
  color: ${({ isSelected }) =>
    isSelected ? color("white") : color("text-dark")};

  /*DX-Comment Changed the styles on hover*/
  &:hover {
    background-color: var(--mb-color-brand);
    color: white;
    > div {
      color: white !important;
      
      /* Target all SVGs within children divs */
      svg {
        color: white !important;
        fill: white !important;
      }
    }
  }

  ${({ isLast }) =>
    !isLast ? `border-bottom: 1px solid ${color("border")}` : ""};

  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const ChunkyList = styled.div`
  border: 1px solid ${color("border")};
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
