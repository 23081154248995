import styled from "@emotion/styled";

import AccordionList from "metabase/core/components/AccordionList";
import { color } from "metabase/lib/colors";

import { FieldList } from "../FieldList";

export const AggregationItemList = styled(AccordionList)`
  color: var(--mb-color-summarize); /*DX-Comment changed color("summarize") to var(--mb-color-summarize)*/
`;

export const AggregationFieldList = styled(FieldList)`
  color: var(--mb-color-summarize); /*DX-Comment changed color("summarize") to var(--mb-color-summarize)*/
`;
