import type { EmotionCache } from "@emotion/react";
import { withEmotionCache } from "@emotion/react";
import type { MantineThemeOverride } from "@mantine/core";
import { MantineProvider } from "@mantine/core";
import { merge } from "icepick";
import { type ReactNode, useEffect, useMemo, useState } from "react";

import { getThemeOverrides } from "../../../theme";
import { DatesProvider } from "../DatesProvider";
import { colors } from "metabase/lib/colors";

interface ThemeProviderProps {
  children: ReactNode;

  /**
   * Extend Metabase's theme overrides.
   * This is primarily used in the React embedding SDK
   * to allow SDK users to customize the theme.
   */
  theme?: MantineThemeOverride;
}

export const ThemeProvider = withEmotionCache(
  (props: ThemeProviderProps, cache: EmotionCache) => {

    //DX-Comment Added changes to get and set brand color
    const [brandColor, setBrandColor] = useState("#51B848"); //default

    //DX-Comment Added hook to capture brand color dynamically from DX web app
    useEffect(() => {
      const updateBrandColor = (event: MessageEvent) => {
        if (event.data?.action === "updateBrandColor") {
          colors.brand = event.data.brandColor
          colors.accent0 = event.data.brandColor
          colors.summarize = event.data.brandColor
          setBrandColor(event.data.brandColor);
        }
      };
  
      window.addEventListener("message", updateBrandColor);
      return () => window.removeEventListener("message", updateBrandColor);
    }, []);
    
    // Merge default theme overrides with user-provided theme overrides
    const theme = useMemo(() => {
      return merge(getThemeOverrides(), props.theme);
    }, [props.theme, brandColor]);

    return (
      <MantineProvider theme={theme} emotionCache={cache}>
        <DatesProvider>{props.children}</DatesProvider>
      </MantineProvider>
    );
  },
);
