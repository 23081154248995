import styled from "@emotion/styled";

import Button from "metabase/core/components/Button";
import { color } from "metabase/lib/colors";

interface ButtonRootProps {
  isSelected?: boolean;
}

export const ButtonRoot = styled(Button)<ButtonRootProps>`
  color: ${props => !props.isSelected && color("text-dark")};
  /*DX-Comment changed color("brand") to var(--mb-color-brand)*/
  &:hover {
    color: ${props => !props.isSelected && "var(--mb-color-brand)"};
  }
`;
