import styled from "@emotion/styled";

import { color } from "metabase/lib/colors";

export const RowCountButton = styled.button<{ highlighted?: boolean }>`
  color: ${props =>
    /*DX-Comment changed color("brand") to var(--mb-color-brand)*/
    props.highlighted ? "var(--mb-color-brand)" : color("text-medium")};
  font-weight: bold;
  cursor: pointer;

  &:hover {
    /*DX-Comment changed color("brand") to var(--mb-color-brand)*/
    color: var(--mb-color-brand);
  }
`;

export const RowCountStaticLabel = styled.span`
  color: ${color("text-medium")};
`;
