import styled from "@emotion/styled";
import type { HTMLAttributes } from "react";

import type { MenuItemProps } from "metabase/ui";
import { Menu } from "metabase/ui";

type MenuItemStyledProps = MenuItemProps & HTMLAttributes<HTMLButtonElement>;

export const MenuItemStyled = styled(Menu.Item)<MenuItemStyledProps>`
  ${({ theme, "aria-selected": isSelected }) =>
    isSelected &&
    `
    background-color: ${theme.fn.themeColor("brand")};
    & .emotion-Menu-itemLabel .emotion-Text-root { /* Target Text within Menu-itemLabel */
      color: white; /*DX-Comment Changed the color to white for selected state */
    }
  `}

  /*DX-Comment Added new property for hover*/
  &:hover {
    background-color: ${props => props.theme.fn.themeColor("brand")}; /* Use props.theme */
    & .emotion-Menu-itemLabel .emotion-Text-root { /* Target Text within Menu-itemLabel */
      color: white;
    }
  }

  /*DX-Comment Added new property for focus*/
  &:focus {
    background-color: ${props => props.theme.fn.themeColor("brand")}; /* Use props.theme */
    & .emotion-Menu-itemLabel .emotion-Text-root { /* Target Text within Menu-itemLabel */
      color: white;
    }
  }
`;
